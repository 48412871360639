:root {
  --color-fill: #f0f2f1;
  --color-primary: #289b53;
  --color-text: #999999;
  --color-disabled: #d9d9d9;
  --color-border: #dbdbdb;
  --color-error: #e5352d;
  --color-secondary: #ff9326;
  --spacing: 20px;
  --spacing-small: 10px;
  --spacing-smallest: 5px;
  --spacing-big: 30px;
  --spacing-large: 40px;
}

body {
  margin: 0;
  font-family: Inter;
  overscroll-behavior-y: contain;
}

label {
  text-transform: uppercase;
  font-size: 15px;
  color: var(--color-text);
  font-weight: 500;
  margin-bottom: var(--spacing-small);
  display: block;
  letter-spacing: 0.45px;
}

.flex-col {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  grid-row-gap: var(--spacing);
  row-gap: var(--spacing);
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex.center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}


@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.settings {
  color: black;
}

.full-width {
  width: 100%;
}

.pt-1 {
  padding-top: 5px;
}

.mt-1 {
  margin-top: 5px;
}

.pt-2 {
  padding-top: 10px;
}

.mt-2 {
  margin-top: 10px;
}

.pt-3 {
  padding-top: 15px;
}

.mt-3 {
  margin-top: 15px;
}

.pt-4 {
  padding-top: 20px;
}

.mt-4 {
  margin-top: 20px;
}

.pt-5 {
  padding-top: 25px;
}

.mt-5 {
  margin-top: 25px;
}

.pt-6 {
  padding-top: 30px;
}

.mt-6 {
  margin-top: 30px;
}

.pr-1 {
  padding-right: 5px;
}

.mr-1 {
  margin-right: 5px;
}

.pr-2 {
  padding-right: 10px;
}

.mr-2 {
  margin-right: 10px;
}

.pr-3 {
  padding-right: 15px;
}

.mr-3 {
  margin-right: 15px;
}

.pr-4 {
  padding-right: 20px;
}

.mr-4 {
  margin-right: 20px;
}

.pr-5 {
  padding-right: 25px;
}

.mr-5 {
  margin-right: 25px;
}

.pr-6 {
  padding-right: 30px;
}

.mr-6 {
  margin-right: 30px;
}

.pb-1 {
  padding-bottom: 5px;
}

.mb-1 {
  margin-bottom: 5px;
}

.pb-2 {
  padding-bottom: 10px;
}

.mb-2 {
  margin-bottom: 10px;
}

.pb-3 {
  padding-bottom: 15px;
}

.mb-3 {
  margin-bottom: 15px;
}

.pb-4 {
  padding-bottom: 20px;
}

.mb-4 {
  margin-bottom: 20px;
}

.pb-5 {
  padding-bottom: 25px;
}

.mb-5 {
  margin-bottom: 25px;
}

.pb-6 {
  padding-bottom: 30px;
}

.mb-6 {
  margin-bottom: 30px;
}

.pl-1 {
  padding-left: 5px;
}

.ml-1 {
  margin-left: 5px;
}

.pl-2 {
  padding-left: 10px;
}

.ml-2 {
  margin-left: 10px;
}

.pl-3 {
  padding-left: 15px;
}

.ml-3 {
  margin-left: 15px;
}

.pl-4 {
  padding-left: 20px;
}

.ml-4 {
  margin-left: 20px;
}

.pl-5 {
  padding-left: 25px;
}

.ml-5 {
  margin-left: 25px;
}

.pl-6 {
  padding-left: 30px;
}

.ml-6 {
  margin-left: 30px;
}
