.outside-state .flex-col-small {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  grid-row-gap: var(--spacing-small);
  row-gap: var(--spacing-small);
}

.outside-state .tab-face div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.outside-state .tab-face div .face {
  border: 2px solid var(--color-disabled);
  width: 70px;
  height: 70px;
  border-radius: 6px;
}

.outside-state .tab-face div .face img {
  max-width: 100%;
  max-height: 100%;
}

.outside-state .tab-face div .face.completed {
  border-color: var(--color-primary);
}

.outside-state .tab-face div .ongoing {
  border-color: var(--color-secondary);
}

.outside-state .d-none {
  display: none;
}

.outside-state p {
  text-transform: uppercase;
  font-weight: 500;
}

.outside-state canvas {
  width: 100%;
  cursor: pointer;
}

.outside-state .face-dommage {
  grid-row-gap: 10px;
  row-gap: 10px;
}

.outside-state .face-dommage table tbody tr td {
  text-align: center;
  height: 38px;
}

.outside-state .face-dommage table tbody tr .number {
  width: 10px;
  padding: 0 6px;
}

.outside-state .face-dommage table tbody tr .flex {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-right: var(--spacing-smallest);
}

.outside-state .face-dommage table tbody tr svg {
  color: var(--color-text);
}

.outside-state .face-dommage table tbody tr p {
  color: black;
  text-transform: none;
}

@media screen and (max-width: 500px) {
  .outside-state .tab-face div .face {
    width: 42px;
    height: 42px;
  }
}
