.client-ald {
    margin-top: 93px;
}

.step-back .value {
    width: calc(100% - var(--spacing-small) * 2 - 4px);
    border-radius: 6px;
    border: 2px solid var(--color-border);
    height: calc(44px - 4px);
    padding: 0 var(--spacing-small);
    font-size: 15px;
    font-weight: 600;
    background-color: white;
    color: var(--color-text);
    opacity: 0.5
}

.step-back .value p {
    margin-top: 10px;
}
