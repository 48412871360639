.actions {
  position: relative;
  padding: 15px var(--spacing);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-column-gap: var(--spacing);
    grid-column-gap: var(--spacing);
          column-gap: var(--spacing);
  background-color: white;
  border-top: 1px solid var(--color-fill);
  width: calc(100% - var(--spacing) * 2);
}

@media screen and (max-width: 1079px) {
  .actions {
    position: fixed;
    bottom: 0;
    left: 0;
    border: 0;
    margin: 0;
    width: calc(100% - var(--spacing) * 2);
    z-index: 2;
  }
  .actions button {
    width: 100%;
  }
}
