.input input {
  width: calc(100% - var(--spacing-small) * 2 - 4px);
  border-radius: 6px;
  border: 2px solid var(--color-border);
  height: calc(44px - 4px);
  padding: 0 var(--spacing-small);
  font-size: 15px;
  font-weight: 600;
  background-color: white;
}

.input input:focus {
  outline: none !important;
  border-color: var(--color-primary);
}

.input input:disabled {
  color: var(--color-text);
  opacity: 0.5;
}

.input.error label {
  color: var(--color-error);
}

.input.error input {
  border-color: var(--color-error);
}
