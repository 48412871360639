button.MuiButton-outlined:disabled {
  color: var(--color-text);
  border-color: var(--color-text);
  opacity: 0.5;
}

button.MuiButton-contained:disabled {
  color: white;
  background-color: var(--color-text);
  opacity: 0.5;
}
