.search-vehicle form .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: var(--spacing);
    grid-column-gap: var(--spacing);
          column-gap: var(--spacing);
}

.search-vehicle .left p.network {
  font-size: 15px;
  margin: 0;
}

.search-vehicle .right {
  padding: 0;
}

.search-vehicle .right .box {
  background-color: var(--color-fill);
  padding: var(--spacing) 15px;
  border-radius: 6px;
}

.search-vehicle .right .box h2 {
  color: black;
}

.search-vehicle .right .box .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  grid-row-gap: var(--spacing-small);
  row-gap: var(--spacing-small);
}

.search-vehicle .right .box .info > span {
  text-transform: uppercase;
  letter-spacing: 0.33px;
}

.search-vehicle .right .box .info p {
  color: black;
}

.search-vehicle .right .box .info button {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
}

.search-vehicle .right .box .info img {
  height: 70px;
  width: 150px;
  border-radius: 6px;
  border: 1px solid var(--color-disabled);
  display: block;
}
