.model-selection-dialog .content p {
  margin: 0;
}

.model-selection-dialog .content .faces {
  width: 130px;
  height: 61px;
}

.model-selection-dialog .content div.grid {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin: auto;
}

.model-selection-dialog .content div.grid label.radio .label {
  padding: 0;
  padding-top: 3px;
}

.model-selection-dialog .content form .radiogroup{
  max-height: 250px;
  overflow: auto;
}