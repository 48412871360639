.img-input {
  width: 100%;
}

.img-input .img {
  position: relative;
}

.img-input input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.img-input .imgbutton {
  height: 44px;
}

.img-input .imgbutton span {
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: var(--spacing-small);
    grid-column-gap: var(--spacing-small);
          column-gap: var(--spacing-small);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.img-input .imgbutton span .text {
  margin-top: 2px;
}

.img-input label {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
  margin: 0;
}

.img-input img {
  width: 100%;
  border-radius: 6px;
}

.img-input .close {
  position: absolute;
  right: var(--spacing);
  top: var(--spacing);
  color: var(--color-text);
  height: 44px;
  width: 44px;
  border-radius: 6px;
  background-color: white;
  border: 1px solid var(--color-disabled);
}
