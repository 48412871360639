.checkbox {
  width: calc(100% - 15px * 2 - 2px);
  min-height: 42px;
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid var(--color-disabled);
  border-radius: 6px;
  color: var(--color-text);
  margin-bottom: 0;
  cursor: pointer;
}

.checkbox svg {
  font-size: 18px;
}

.checkbox.filled {
  background-color: var(--color-fill);
  border-color: var(--color-fill);
  color: black;
}

.checkbox.filled.disabled {
  background-color: var(--color-fill);
}

.checkbox.disabled {
  background-color: var(--color-disabled);
  opacity: 0.5;
}

.checkbox .label {
  padding: 11px 0;
  text-transform: none;
  letter-spacing: 0;
}
