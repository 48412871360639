.gauge .slider {
  border-radius: 22.5px;
  height: 45px;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.gauge .slider .error {
  border-color: var(--color-error);
}

.gauge p {
  margin-bottom: 0;
  margin-top: var(--spacing-smallest);
  font-size: 11px;
  letter-spacing: 0.33px;
}

.gauge .flex{
  justify-content: space-between;
  align-items: center;
}
.gauge .flex label{
  margin-bottom: 0;
}
