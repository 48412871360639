.stepper {
  min-height: calc(100vh - 90px);
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 350px auto;
      grid-template-columns: 350px auto;
}

.stepper .steplabel {
  -ms-grid-column: 1;
  grid-column: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: calc(100% - var(--spacing) * 2);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  -webkit-column-gap: 15px;
    grid-column-gap: 15px;
          column-gap: 15px;
  padding-left: var(--spacing);
  padding-right: var(--spacing);
  padding-top: var(--spacing);
  background-color: white;
}

.stepper .steplabel .connector {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  height: 24px;
  border-left: 2px solid var(--color-disabled);
  z-index: 1;
}

.stepper .steplabel:last-child .connector {
  display: none;
}

.stepper .steplabel h3 {
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.55px;
}

.stepper .steplabel h2 {
  letter-spacing: 0;
  white-space: nowrap;
}

.stepper .steplabel h2.black {
  color: black;
}

.stepper .steplabel .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 15px;
    grid-column-gap: 15px;
          column-gap: 15px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.stepper .steplabel .content .MuiChip-root {
  margin-left: auto;
}

.stepper .steplabel .content .icon {
  width: 28px;
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 2px solid var(--color-disabled);
  color: var(--color-text);
  border-radius: 4px;
}

.stepper .steplabel.active .icon {
  -webkit-box-shadow: 0 2px 5px 0 #d9d9d9;
          box-shadow: 0 2px 5px 0 #d9d9d9;
}

.stepper .steplabel.done .content {
  cursor: pointer;
}

.stepper .steplabel.done .icon {
  color: white;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.stepper .steplabel.done .connector {
  border-color: var(--color-primary);
}

.stepper .steplabel.progress .content {
  cursor: pointer;
}

.stepper .steplabel.progress .icon {
  color: var(--color-secondary);
  border-color: var(--color-secondary);
}

.stepper .steplabel.disabled .icon {
  color: white;
  background-color: var(--color-text);
  border-color: var(--color-text);
}

.stepper .stepcontent {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
      grid-row-start: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100vh - 90px);
  overflow: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-left: 1px solid var(--color-fill);
}

.stepper .stepcontent.done .layout {
  border-color: var(--color-primary);
}

.layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-column-gap: var(--spacing);
    grid-column-gap: var(--spacing);
          column-gap: var(--spacing);
  padding: 15px var(--spacing);
}

.layout .left {
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
}

.layout .right {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
}

.layout .left,
.layout .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  grid-row-gap: var(--spacing);
  row-gap: var(--spacing);
  padding: var(--spacing);
}

@media screen and (max-width: 1079px) {
  .stepper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-bottom: 105px;
  }
  .stepper .stepcontent {
    border: 0;
    max-height: none;
  }
  .stepper .stepcontent .layout {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    grid-row-gap: var(--spacing);
    row-gap: var(--spacing);
    margin: 10px 0;
    margin-left: 35px;
    border-left: 2px solid var(--color-disabled);
    padding: var(--spacing);
    padding-left: var(--spacing-big);
  }
  .stepper .stepcontent .layout .left,
  .stepper .stepcontent .layout .right {
    padding: 0;
  }

  .stepper .steplabel {
    padding-top: 0;
  }

  .stepper .steplabel:first-child {
    padding-top: var(--spacing);
  }
}
