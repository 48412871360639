.textarea textarea {
  width: calc(100% - 15px * 2 - 4px);
  border-radius: 6px;
  border: 2px solid var(--color-border);
  padding: 13px 15px;
  font-size: 15px;
  font-weight: 600;
}

.textarea textarea:focus {
  outline: none !important;
  border-color: var(--color-primary);
}

.textarea textarea:disabled {
  color: var(--color-text);
  opacity: 0.5;
}

.textarea.error label {
  color: var(--color-error);
}

.textarea.error textarea {
  border-color: var(--color-error);
}

.textarea.error p {
  margin-bottom: 0;
  margin-top: var(--spacing-smallest);
  font-size: 11px;
  letter-spacing: 0.33px;
  color: var(--color-error);
}
