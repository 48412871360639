header {
  width: 100%;
  height: 90px;
  background-color: var(--color-fill);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

header h1 {
  white-space: nowrap;
  padding: 13px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 15px;
    grid-column-gap: 15px;
          column-gap: 15px;
}

header h1 div {
  margin-left: auto;
  color: black;
}

header h1 .version {
    margin-left: auto;
    font-weight: normal;
    color: black;
}

header .upload-banner {
    cursor: pointer;
    align-items: center;
    background-color: var(--color-secondary);
    height: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

@media screen and (max-width: 500px) {
    header .upload-banner p {
        font-size: 11px;
    }
}
