.number-input .component {
  border-radius: 6px;
  background-color: var(--color-fill);
  height: 44px;
  width: 151px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.number-input .component button {
  width: 44px;
  height: 44px;
}

.number-input .component .value {
  background-color: white;
  height: 36px;
  width: 63px;
  border-radius: 6px;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.number-input input[type="number"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
