.signature .sigPad {
  width: calc(100% - 4px);
  min-height: 150px;
  border-radius: 6px;
  border: 2px solid var(--color-border);
}

.signature .sigPad.focus {
  outline: none !important;
  border-color: var(--color-primary);
}

.signature .sigPad:disabled {
  color: var(--color-text);
  opacity: 0.5;
}

.signature.error label {
  color: var(--color-error);
}

.signature.error .sigPad {
  border-color: var(--color-error);
}

.signature .flex{
  justify-content: space-between;
  align-items: center;
}
.signature .flex label{
  margin-bottom: 0;
}
