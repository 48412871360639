.radio {
  width: calc(100% - 15px * 2 - 2px);
  min-height: 42px;
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid var(--color-disabled);
  border-radius: 6px;
  color: var(--color-text);
  margin-bottom: 0;
  cursor: pointer;
}

.radio svg {
  font-size: 18px;
}

.radio.filled {
  background-color: var(--color-fill);
  border-color: var(--color-fill);
  color: black;
}

.radio.filled.disabled {
  background-color: var(--color-fill);
}

.radio.disabled {
  background-color: var(--color-disabled);
  opacity: 0.5;
}

.radio .option-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
}

.radio .label {
  padding: 11px 0;
  text-transform: none;
  letter-spacing: 0;
}

.radio .img {
  padding: 11px 0;
}

.radiogroup .grid {
  display: -ms-grid;
  display: grid;
  grid-row-gap: var(--spacing-small);
  row-gap: var(--spacing-small);
  -webkit-column-gap: var(--spacing);
    grid-column-gap: var(--spacing);
          column-gap: var(--spacing);
}

.radiogroup .grid.row {
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.radiogroup .grid.row-tool {
  -ms-grid-columns: (1fr)[3];
  grid-template-columns: repeat(3, 1fr);
}

.radiogroup .grid.column {
  -ms-grid-columns: auto;
      grid-template-columns: auto;
}

@media screen and (max-width: 1079px) {
  .radiogroup .grid.row {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }
  .radiogroup .grid.row-tool {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }
}
